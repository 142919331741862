.masters-projects {
    padding: 40px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
        margin-bottom: 20px;
        color: #333;
    }

    .projects-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;

        .column {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(50% - 20px);
        }
    }

    .project-item {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        width: calc(50% - 20px); // Adjust if necessary for spacing
        min-height: 180px;

        h2 {
            color: #222;
            margin: 10px 0;
        }

        p {
            font-size: 16px;
            color: #666;
            margin: 10px 0;
        }

        a {
            display: inline-block;
            margin-top: 20px;
            padding: 10px 20px;
            border-radius: 5px;
            text-decoration: none;
            transition: background-color 0.3s;
            font-weight: bold;
            text-align: center;
            cursor: pointer;
            white-space: nowrap; // Prevents the links from wrapping

            // Styling for the Notion link
            &.notion-link {
                background-color: #0056b3;
                color: white;
            }

            // Styling for the PDF link
            &.pdf-link {
                background-color: #ff6f61;
                color: white;
            }

            // Styling for the download button
            &.download-button {
                background-color: #4caf50;
                color: white;
            }

            &:hover {
                opacity: 0.9;
            }
        }
    }
}

@media (max-width: 768px) {
    .projects-container {
        flex-direction: column;
        align-items: center;

        .column {
            width: 100%; // Each column takes full width on small screens
        }
    }
}

@import "../../global.scss";

.intro {
    display: flex;
    @include mobile{
        flex-direction: column;
    }

    .left{
        flex: 0.4;
        overflow: hidden;
        @include mobile{
            align-items: flex-start;
        }

        .imgContainer{
            width: 1000px;
            height: 1000px;

            img{
                height: 90%;
                @include mobile{
                    height: 37%;
                    width: 37%;
                }
            }
        }
    }

    .right{
        flex: 0.6;
        position: relative;
        background-color: rgb(255, 123, 0);

        .wrapper{
            height: 90%;
            width: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile{
                padding-left: 0;
                align-items: center;
            }
            h1{
                font-size: 60px;
                margin: 10px 0;
                color: azure;

                @include mobile{
                    font-size: 40px;
                }
            }
            h2{
                font-size: 35px;
                color: azure;
            }
            h3{
                font-size: 30px;
                color: azure;
                @include mobile{
                    font-size: 20px;
                }
                span{
                    font-size: inherit;
                    color: greenyellow;
                }

                .ityped-cursor{
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    50%{
                        opacity: 1;
                    }
                    100%{
                        opacity: 0;
                    }
                }
            }
            
        }
        a{
            position: absolute;
            bottom: 10px;
            left: 50%;

            img{
                width: 50px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
            }
        }
    }
}
@import "../../global.scss";

.topbar {
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
    z-index: 3;

    .wrapper {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left{
            display: flex;
            align-items: center;

            .toggle{
                width: 50px;
                height: 25px;
                border-radius: 20px;
                border: 1px solid #999;
                background-color: white;
                position: fixed;
                top: 10px;
                left: 10px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                .toggleIcon{
                    width: 15px;
                    height: 15px;
                }

                .toggleButton{
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background-color: #999;
                    position: absolute;
                    cursor: pointer;
                }
            }

            .intro{
                a{
                    img{
                        width: 40px;
                        transform: rotateX(180deg);
                        position:absolute;
                        left: 40px;
                        animation: arrowBlink 2s infinite;
                    }
                    @keyframes arrowBlink {
                        100%{
                            opacity: 0;
                        }
                    }
                }
            }
            
            .logo{
                font-size: 40px;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                margin-right: 40px;
            }

            .itemContainer{
                display: flex;
                align-items: center;
                margin-left: 30px;

                @include mobile{
                    display: none;
                }

                .icon{
                    font-size: 18px;
                    margin-right: 5px;
                }

                .span{
                    font-size: 15px;
                    font-weight: 500;
                }
            }
            .imageLinks{
                a{
                    img{
                        height: 35px;
                        margin-left: 10px;

                        @include mobile{
                            margin-left: 45px;
                        }
                    }
                }
            }
        }
        .right{

            .beyondBurger{
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;

                span{
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 2s ease;
                }
            }
        }
    }
    &.active{
        background-color: $mainColor;
        color: white;

        .beyondBurger{
            span{
                &:first-child{
                    background-color: white;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child{
                    background-color: white;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}